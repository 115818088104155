import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, NgxsOnInit } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { AppStateModel } from './app-state.model';

//import { NotificationService } from 'src/app/core/services/notification.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { of } from 'rxjs';
import { MatchDay, Season } from '../../services/sdp.messages';
import {
  CreateMatchReportRequest,
  CreateMatchReportRequestForSelectedMatch,
  GetCompetitions,
  GetMatches,
  GetReport,
  GetReportsTemplates,
  GetSeasons,
  JoinStatusUpdateGroup,
  LeaveStatusUpdateGroup,
  SelectCompetition,
  SelectMatchDay,
  SelectSeason,
  UpdateMatchReportStatus,
} from './app-state.actions';
import { ReportingService } from '../../services/reporting.service';
import { WebSocketService } from '../../services/websocket.service';
import { GetReportTemplates } from '../../services/reporting.messages';
import { SdpService } from '../../services/sdp.service';
import { BaseState } from '../BaseState';
import { StateService } from '../../services/state.service';

@State<AppStateModel>({
  name: 'app',
  defaults: {
    competitions: [],
    seasons: [],
    matchDays: [],
    matches: [],
    matchReportBundles: [],
    sessionReportBundles: [],

    selectedCompetition: null,
    selectedSeason: null,
    selectedMatchDay: null,
    selectedMatch: null,

    seasonsLoading: false,
    competitionsLoading: false,
    matchesLoading: false,
    selectedSessionReportsBundleLoading: false,
    matchReportBundleLoading: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class AppState extends BaseState implements NgxsOnInit {
  constructor(
    private sdpService: SdpService,
    private reportingService: ReportingService,
    private webSocketService: WebSocketService,
    stateService: StateService,
  ) {
    super(stateService);
  }

  ngxsOnInit(ctx: StateContext<AppStateModel>) {
    this.store.dispatch(new GetCompetitions());
    this.connectToWebSocket();
  }

  connectToWebSocket() {
    this.webSocketService.startConnection();
    this.webSocketService.addReceiveMessageListener((message) => {
      console.log(message);
      this.store.dispatch(new UpdateMatchReportStatus(message));
    });
  }

  @Action(GetCompetitions)
  getCompetitions(ctx: StateContext<AppStateModel>) {
    ctx.setState(
      patch({
        competitions: [],
        competitionsLoading: true,
      })
    );

    return this.sdpService.getAllCompetitions().pipe(
      tap((competitions) => {
        ctx.setState(
          patch({
            competitions: competitions,
            competitionsLoading: false,
          })
        );
      }),
      catchError((error) => {
        ctx.setState(
          patch({
            competitionsLoading: false,
          })
        );

        return throwError(() => new Error(error));
      })
    );
  }

  @Action(SelectCompetition)
  selectCompetition(ctx: StateContext<AppStateModel>, act: SelectCompetition) {
    const competition = ctx
      .getState()
      .competitions.find((x) => x.CompetitionID === act.competitionId);

    if (!competition) return;

    this.store.dispatch(new LeaveStatusUpdateGroup(this.snapshot.selectedCompetition?.CompetitionID));

    ctx.setState(
      patch({
        selectedCompetition: competition,
      })
    );

    this.store.dispatch(new JoinStatusUpdateGroup(act.competitionId));

    return this.store.dispatch(new GetSeasons(act.competitionId));
  }

  @Action(GetSeasons)
  getSeasons(ctx: StateContext<AppStateModel>, act: GetSeasons) {
    ctx.setState(
      patch({
        seasons: [] as Season[],
        seasonsLoading: true,
      })
    );

    if (act.competitionId) {
      return this.sdpService.GetSeasons(act.competitionId).pipe(
        tap((seasons) => {
          ctx.setState(
            patch({
              seasons,
              seasonsLoading: false,
            })
          );
        }),
        catchError((error) => {
          ctx.setState(
            patch({
              seasonsLoading: false,
            })
          );

          return throwError(() => new Error(error));
        })
      );
    } else {
      ctx.setState(
        patch({
          competitionsLoading: false,
        })
      );

      return of();
    }
  }

  @Action(SelectSeason)
  selectSeason(ctx: StateContext<AppStateModel>, act: SelectSeason) {
    /* this.store.dispatch(
      new LeaveStatusUpdateGroup(
        ctx.getState().selectedCompetition?.CompetitionID
      )
    ); */
    const season = ctx
      .getState()
      .seasons.find((x) => x.SeasonID === act.seasonId);
    if (!season) return;
    ctx.setState(
      patch({
        selectedSeason: season,
      })
    );

    this.store.dispatch(new GetMatches(act.seasonId));
    // this.store.dispatch(new JoinStatusUpdateGroup(act.competitionId));
  }

  @Action(GetMatches)
  getMatches(ctx: StateContext<AppStateModel>, act: GetMatches) {
    ctx.setState(
      patch({
        matches: [],
        matchesLoading: true,
      })
    );

    if (act.sessionId) {
      return this.sdpService.GetMatches(act.sessionId).pipe(
        tap((matches) => {
          const matchDays = matches.reduce<MatchDay[]>((acc, match) => {
            const indexOfExistingMatchDay = acc.findIndex(
              (m) => m.MatchDay === match.MatchDay
            );

            if (indexOfExistingMatchDay > -1) {
              acc[indexOfExistingMatchDay].Matches!.push(match);

              return acc;
            }

            acc.push({
              MatchDay: match.MatchDay,
              Name: match.MatchDay + ' - ' + match.MatchDate,
              Matches: [match],
            });

            return acc;
          }, []);

          ctx.setState(
            patch({
              matchDays,
            })
          );
        }),
        catchError((error) => {
          ctx.setState(
            patch({
              matchesLoading: false,
            })
          );

          return throwError(() => new Error(error));
        })
      );
    } else {
      ctx.setState(
        patch({
          matchesLoading: false,
        })
      );

      return of();
    }
  }

  @Action(SelectMatchDay)
  selectMatchDay(ctx: StateContext<AppStateModel>, act: SelectMatchDay) {
    const matchDay = ctx
      .getState()
      .matchDays.find((x) => x.MatchDay === act.matchDayId);

    if (!matchDay) return;

    ctx.setState(
      patch({
        selectedMatchDay: matchDay,
        matches: matchDay.Matches ?? [],
      })
    );

    this.store.dispatch(new GetReportsTemplates());
  }

  @Action(GetReportsTemplates)
  getReportsTemplates(
    ctx: StateContext<AppStateModel>,
    act: GetReportsTemplates
  ) {
    console.log(act);

    const { matchDays, selectedMatchDay } = ctx.getState();

    const matches = matchDays.find(
      (x) => x.MatchDay === selectedMatchDay?.MatchDay
    )?.Matches;

    const request: GetReportTemplates.Request = {
      matches: matches?.map((x) => <string>x.MatchID?.toString()) ?? [],
      competitionId: ctx.getState().selectedCompetition?.CompetitionID,
    };

    return this.reportingService.getReportTemplates(request).pipe(
      tap((response) => {
        console.log(response);
        ctx.setState(
          patch({
            matchReportBundles: response?.reportBundles,
            matchReportBundleLoading: false,
          })
        );
      }),
      catchError((error) => {
        ctx.setState(
          patch({
            matchesLoading: false,
          })
        );

        //this.notificationService.error({
        //  content: error,
        //});

        return throwError(() => new Error(error));
      })
    );
  }

  @Action(GetReport)
  getReports(ctx: StateContext<AppStateModel>, act: GetReport) {
    console.log(act);

    return this.reportingService.getReport(act.reportId).pipe(
      tap((response) => {
        console.log(response);
        const matchReportBundles = ctx.getState().matchReportBundles;
        for (const matchReportBundle of matchReportBundles) {
          if (matchReportBundle.matchId === response.contextID) {
            for (const reportTemplate of matchReportBundle.matchReportTemplates) {
              if (reportTemplate.reportTemplateId === response.reportTemplateID) {
                reportTemplate.report = response;
                break;
              }
            }
            break;
          }
        }
        ctx.setState(
          patch({
            matchReportBundles: matchReportBundles,
            matchReportBundleLoading: false,
          })
        );
      }),
      catchError((error) => {
        ctx.setState(
          patch({
            matchesLoading: false,
          })
        );

        //this.notificationService.error({
        //  content: error,
        //});

        return throwError(() => new Error(error));
      })
    );
  }

  @Action(CreateMatchReportRequest)
  createReportRequest(
    ctx: StateContext<AppStateModel>,
    act: CreateMatchReportRequest
  ) {
    const match = ctx
      .getState()
      .matches?.find((x) => x.MatchID === act.matchId);
    if (!match) return;

    ctx.patchState({
      selectedMatch: match,
    });

    this.store.dispatch(
      new CreateMatchReportRequestForSelectedMatch(
        act.matchId,
        act.reportTemplateId,
        act.seasonId,
        act.competitionId,
      )
    );
  }

  @Action(CreateMatchReportRequestForSelectedMatch)
  createReportRequestForSelectedMatch(
    ctx: StateContext<AppStateModel>,
    act: CreateMatchReportRequestForSelectedMatch
  ) {
    console.log(act);
    return this.reportingService
      .createMatchReportRequest(act.matchId, act.reportTemplateId, act.seasonId, act.competitionId)
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        catchError((error) => {
          ctx.setState(
            patch({
              matchesLoading: false,
            })
          );

          //this.notificationService.error({
          //  content: error,
          //});

          return throwError(() => new Error(error));
        })
      );
  }

  @Action(JoinStatusUpdateGroup)
  joinStatusUpdateGroup(
    ctx: StateContext<AppStateModel>,
    act: JoinStatusUpdateGroup
  ) {
    console.log(act);
    if (!act.competitionId) return;

    return this.webSocketService.joinGroup(act.competitionId).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError((error) => {
        console.warn(error);
        //this.notificationService.error({
        //  content: error,
        //});

        return throwError(() => new Error(error));
      })
    );
  }

  @Action(LeaveStatusUpdateGroup)
  leaveStatusUpdateGroup(
    ctx: StateContext<AppStateModel>,
    act: LeaveStatusUpdateGroup
  ) {
    console.log(act);
    if (!act.competitionId) return;

    return this.webSocketService.leaveGroup(act.competitionId).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError((error) => {
        console.warn(error);
        //this.notificationService.error({
        //  content: error,
        //});

        return throwError(() => new Error(error));
      })
    );
  }

  @Action(UpdateMatchReportStatus)
  updateMatchReportStatus(
    ctx: StateContext<AppStateModel>,
    act: UpdateMatchReportStatus
  ) {
    console.log(act);

    if (!act.statusUpdate?.contextID || !act.statusUpdate?.reportTemplateId)
      return;

    const matchReportBundle = ctx
      .getState()
      .matchReportBundles.find((x) => x.matchId === act.statusUpdate?.contextID);
    if (!matchReportBundle) return;

    const reportTemplate = matchReportBundle.matchReportTemplates?.find(
      (x) =>
        x.reportTemplateId === act.statusUpdate?.reportTemplateId &&
        matchReportBundle.matchId === act.statusUpdate?.contextID
    );
    if (!reportTemplate) return;

    if (!reportTemplate.report) {
      this.store.dispatch(new GetReport(act.statusUpdate?.reportId));
      return;
    }

    reportTemplate.report.status = act.statusUpdate?.status;
    reportTemplate.report.id = act.statusUpdate?.reportId;
  }
}
