import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';
import { from, Observable, of } from "rxjs";
import { StatusUpdate } from "./reporting.messages";
import { EnvService } from "../../environments/environment.service";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private hubConnection: signalR.HubConnection;

  constructor(private env: EnvService) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.env.apiUrl}/report-status-update`)
      .build();
  }

  public startConnection(): void {
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  // public joinGroup(competitionId: string): Observable<void> {
  //   if (!this.hubConnection.connectionId)
  //     return of();
  //   return from(this.hubConnection.invoke('JoinGroup', competitionId));
  // }

  // public leaveGroup(competitionId: string): Observable<void> {
  //   if (!this.hubConnection.connectionId)
  //     return of();
  //   return from(this.hubConnection.invoke('LeaveGroup', competitionId));
  // }
  public joinGroup(competitionId: string): Observable<void> {
    console.log(`Joining group competitionID: ${competitionId}`);
    if(this.hubConnection.state !== signalR.HubConnectionState.Connected)
    {
      console.log('Connection is not started');
      return of();
    }
    return from(this.hubConnection.invoke('JoinGroup', competitionId));
  }

  public leaveGroup(competitionId: string): Observable<void> {
    console.log(`Joining group competitionID: ${competitionId}`);
    if(this.hubConnection.state !== signalR.HubConnectionState.Connected)
    {
      console.log('Connection is not started');
      return of();
    }
    return from(this.hubConnection.invoke('LeaveGroup', competitionId));
  }

  private ensureConnection(): Promise<void> {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      return Promise.resolve();
    }
    return this.hubConnection.start()
      .then(() => console.log('Connection started'))
      .catch(err => {
        console.error('Error while starting connection: ' + err);
        throw err;
      });
  }

  public addReceiveMessageListener(callback: (message: StatusUpdate) => void): void {
    this.hubConnection.on('MatchReportStatusUpdate', (statusUpdate) => {
      callback(statusUpdate);
    });
  }
}
