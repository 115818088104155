import { StatusUpdate } from '../../services/reporting.messages';

export class GetCompetitions {
  static readonly type = '[app] Get Competitions';
  constructor() {}
}

export class SelectCompetition {
  static readonly type = '[app] Select competition';
  constructor(public competitionId: string | null | undefined) {}
}

export class GetSeasons {
  static readonly type = '[app] Get seasons';
  constructor(public competitionId: string | null | undefined) {}
}

export class SelectSeason {
  static readonly type = '[app] Select season';
  constructor(public seasonId: string | null | undefined) {}
}

export class GetRounds {
  static readonly type = '[app] Get rounds';
  constructor(public seasonId: string | null | undefined) {}
}

export class SelectRound {
  static readonly type = '[app] Select round';
  constructor(public roundId: string | null | undefined) {}
}

export class GetMatchDays {
  static readonly type = '[app] Get match days';
  constructor(public roundIdId: string | null | undefined) {}
}

export class SelectMatchDay {
  static readonly type = '[app] Select matchDay';
  constructor(public matchDayId: string | null | undefined) {}
}

export class GetSessions {
  static readonly type = '[app] Get Sessions';
  constructor(public matchDayId?: string | null) {}
}

export class GetSession {
  static readonly type = '[app] Get Session';
  constructor(public sessionId?: string | null) {}
}

export class SelectSession {
  static readonly type = '[app] Select Session';
  constructor(public sessionId?: string | null) {}
}

export class GetMatches {
  static readonly type = '[app] Get matches';
  constructor(public sessionId: string | null | undefined) {}
}

export class GetReportsTemplates {
  static readonly type = '[app] Get reports';
  constructor() {}
}

export class GetReportsSatuses {
  static readonly type = '[app] Get reports';
  constructor(public matchIds: string[]) {}
}

export class GetReport {
  static readonly type = '[app] Get report';
  constructor(public reportId?: string | null) {}
}

export class CreateMatchReportRequest {
  static readonly type = '[app] Create Report Request';
  constructor(
    public matchId: string | null | undefined,
    public reportTemplateId: string | null | undefined,
    public seasonId: string | null | undefined,
    public competitionId: string | null | undefined,
  ) {}
}

export class CreateMatchReportRequestForSelectedMatch {
  static readonly type = '[app] Create Report Request For Selected Match';
  constructor(
    public matchId: string | null | undefined,
    public reportTemplateId: string | null | undefined,
    public seasonId: string | null | undefined,
    public competitionId: string | null | undefined,
  ) {}
}

export class JoinStatusUpdateGroup {
  static readonly type = '[app] Join Status Update Group';
  constructor(public competitionId: string | null | undefined) {}
}

export class LeaveStatusUpdateGroup {
  static readonly type = '[app] Leave Status Update Group';
  constructor(public competitionId: string | null | undefined) {}
}

export class UpdateMatchReportStatus {
  static readonly type = '[app] Update Match Report Status';
  constructor(public statusUpdate?: StatusUpdate | null) {}
}
