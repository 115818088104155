<nav class="navbar navbar-expand-sm navbar-dark navbar-secondary">
  <div class="container-fluid">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link active" href="#">Serie A</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Placeholder</a>
      </li>
    </ul>
  </div>
</nav>
