import { Component, OnInit } from '@angular/core';
import { Competition, Season } from '../../services/sdp.messages';
import { Store } from '@ngxs/store';
import { AppStateModel } from '../../states/app-state/app-state.model';
import {
  SelectCompetition,
  SelectSeason,
} from '../../states/app-state/app-state.actions';

@Component({
  selector: 'app-competition-selector',
  templateUrl: './competition-selector.component.html',
  styleUrl: './competition-selector.component.less',
})
export class CompetitionSelectorComponent implements OnInit {
  competitions: Competition[] = [];
  competitionsLoading: boolean | null | undefined = false;
  selectedCompetition: Competition | null | undefined = null;

  seasons: Season[] = [];
  seasonsLoading: boolean | null | undefined = false;
  selectedSeason: Season | null | undefined = null;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select((state: { app: AppStateModel }) => state.app.competitions)
      .subscribe((data) => (this.competitions = data));
    this.store
      .select((state: { app: AppStateModel }) => state.app.competitionsLoading)
      .subscribe((data) => (this.competitionsLoading = data));
    this.store
      .select((state: { app: AppStateModel }) => state.app.selectedCompetition)
      .subscribe((data) => (this.selectedCompetition = data));

    this.store
      .select((state: { app: AppStateModel }) => state.app.seasons)
      .subscribe((data) => (this.seasons = data));
    this.store
      .select((state: { app: AppStateModel }) => state.app.seasonsLoading)
      .subscribe((data) => (this.seasonsLoading = data));
    this.store
      .select((state: { app: AppStateModel }) => state.app.selectedSeason)
      .subscribe((data) => (this.selectedSeason = data));
  }

  public onCollapseChange(isActivated: any, competitionId: string | undefined) {
    if (isActivated) this.store.dispatch(new SelectCompetition(competitionId));
  }

  public onSeasonSelect(seasonId: string | undefined) {
    this.store.dispatch(new SelectSeason(seasonId));
  }
}
