import { Component } from '@angular/core';
import { EnvServiceProvider } from '../environments/environment.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less'
})
export class AppComponent {
  title = 'rps';
}
