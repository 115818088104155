<div class="app-competition-round-list-item">
  <ng-container *ngIf="true && selectedCompetition && selectedMatchDay">
    <app-match-report-card
      *ngFor="let match of matches"
      [match]="match"
      [matchReportBundle]="getMatchReportBundle(match?.MatchID)"
    ></app-match-report-card>
    <nz-divider></nz-divider>
    <!-- <app-session-report-card></app-session-report-card> -->
  </ng-container>
</div>
