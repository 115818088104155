import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppStateModel } from '../../states/app-state/app-state.model';
import { GetReportTemplates } from '../../services/reporting.messages';
import { Competition, Match, MatchDay } from '../../services/sdp.messages';

@Component({
  selector: 'app-report-screen',
  templateUrl: './report-screen.component.html',
  styleUrl: './report-screen.component.less',
})
export class ReportScreenComponent {
  matchReportTemplates?: GetReportTemplates.ReportBundle[] | null;
  sessionReportTemplates?: any | null;
  selectedCompetition?: Competition | null;
  selectedMatchDay?: MatchDay | null;
  matches: Match[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select((state: { app: AppStateModel }) => state.app)
      .subscribe((data) => {
        this.selectedCompetition = data.selectedCompetition;
        this.selectedMatchDay = data.selectedMatchDay;
        this.matchReportTemplates = data.matchReportBundles;
        this.matches = data.matches;
      });
  }

  getMatchReportBundle(
    matchId: string | undefined
  ): GetReportTemplates.ReportBundle | undefined {
    return this.matchReportTemplates?.find((x) => x.matchId == matchId);
  }
}
