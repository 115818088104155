import { NgModule } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { NavbarSecondaryComponent } from './navbar-secondary/navbar-secondary.component';
import { LayoutComponent } from './layout/layout.component';
import { NavbarPrimaryComponent } from './navbar-primary/navbar-primary.component';
import { UiVerticalDividerComponent } from './ui-vertical-divider/ui-vertical-divider.component';
import { CommonModule } from '@angular/common';

import { CompetitionSelectorComponent } from './components/competition-selector/competition-selector.component';
import { MatchdaySelectorComponent } from './components/matchday-selector/matchday-selector.component';
import { ReportScreenComponent } from './components/report-screen/report-screen.component';

import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';

import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './states/app-state/app-state';
import { NzCardModule } from 'ng-zorro-antd/card';
import { MatchReportCardComponent } from './components/report-screen/match-report-card/match-report-card.component';
import { SessionReportCardComponent } from './components/report-screen/session-report-card/session-report-card.component';

import { NzDividerModule } from 'ng-zorro-antd/divider';

registerLocaleData(en);
const COMPONENTS = [
  NavbarPrimaryComponent,
  NavbarSecondaryComponent,
  LayoutComponent,
  UiVerticalDividerComponent,
  CompetitionSelectorComponent,
  MatchdaySelectorComponent,
  ReportScreenComponent,
  MatchReportCardComponent,
  SessionReportCardComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    NgbAccordionModule,
    NzDividerModule,
    CommonModule,
    BrowserAnimationsModule,
    NzCollapseModule,
    NzSpinModule,
    NzAlertModule,
    NzCardModule,
    NzTreeModule,
    NzIconModule,
    NzListModule,
    FormsModule,
    NgxsModule.forRoot([AppState]),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    provideAnimationsAsync(),
    provideHttpClient(),
  ],
  exports: [...COMPONENTS],
})
export class CoreModule {}
