import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatchDay } from '../../../services/sdp.messages';

@Component({
  selector: 'app-session-report-card',
  templateUrl: './session-report-card.component.html',
  styleUrl: './session-report-card.component.less',
})
export class SessionReportCardComponent implements OnInit {
  @Input() matchDay?: MatchDay | null;
  @Input() reportsTemplate?: any | null;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  onReportClick(sessionId?: string, reportName?: string) {
    //this.store.dispatch(new CreateMatchReportRequest(sessionId, reportName))
  }
}
