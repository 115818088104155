import { Component, Input, OnInit } from '@angular/core';
import { CreateMatchReportRequest } from '../../../states/app-state/app-state.actions';
import { Store } from '@ngxs/store';
import { Match } from '../../../services/sdp.messages';
import { GetReportTemplates } from '../../../services/reporting.messages';
import { EnvService } from '../../../../environments/environment.service';

@Component({
  selector: 'app-match-report-card',
  templateUrl: './match-report-card.component.html',
  styleUrl: './match-report-card.component.less',
})
export class MatchReportCardComponent implements OnInit {
  @Input() match?: Match | null;
  @Input() sessionId?: string | null;
  @Input() matchReportBundle?: GetReportTemplates.ReportBundle | null;

  statusColors: any = {
    '0': 'white',
    '1': 'lightblue',
    '2': 'blue',
    '3': 'yellow',
    '4': 'green',
    '5': 'red',
    undefined: 'gray',
  };

  constructor(private store: Store, private env: EnvService) {}

  ngOnInit(): void {
    console.log('MatchID:', this.match?.MatchID);
  }

  ngOnDestroy() {
    console.log('DESTROY MatchID:', this.match?.MatchID);
    if (this.match?.MatchID) {
    }
  }

  onReportClick(matchId?: string, reportId?: string) {
    this.store.dispatch(
      new CreateMatchReportRequest(
        matchId,
        reportId,
        this.match?.MatchData?.SeasonID,
        this.match?.MatchData?.CompetitionID,
      )
    );
  }

  onPreview(reportId?: string) {
    const url = `${this.env.rpsApiUrl}/api/reports/${reportId}/preview`;
    window.open(url, '_blank');
  }

  onDownload(reportId?: string) {
    const url = `${this.env.rpsApiUrl}/api/reports/${reportId}/download`;
    window.open(url, '_blank');
  }
}
