export class EnvService {
  // API url
  public apiUrl = '';
  public rpsApiUrl = '';
  public production = true;

  constructor() {}
}

export const EnvServiceFactory = () => {
  const env = new EnvService();

  const browserWindow = window || {};
  const browserWindowEnv = window && (window as any)['__env'];

  if (!browserWindowEnv) {
    throw new Error(
      `Couldn't find __env in the window, have you properly setup the configuration file (env.js)?`
    );
  }

  // Assign environment variables from browser window to the environmentService
  // Properties from env.js overwrite defaults
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      (env as any)[key] = (browserWindowEnv as any)[key];
    }
  }

  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
