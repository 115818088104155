import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Competition, Match, MatchDay, Season } from './sdp.messages';
import { EnvService } from '../../environments/environment.service';

@Injectable({
  providedIn: 'root',
})
export class SdpService {
  constructor(private http: HttpClient, private env: EnvService) {}

  public getAllCompetitions(): Observable<Competition[]> {
    return this.http.get(`${this.env.apiUrl}/api/competitions`).pipe(
      map((data: any) =>
        data.competitions.map(
          (c: any): Competition => ({
            CompetitionID: c.competitionId,
            Name: c.name,
          })
        )
      )
    );
  }

  public GetSeasons(competitionId: string): Observable<Season[]> {
    return this.http
      .get(`${this.env.apiUrl}/api/competitions/${competitionId}/seasons`)
      .pipe(
        map((data: any) =>
          data.seasons.map(
            (s: any): Season => ({
              SeasonID: s.seasonId,
              Name: s.seasonName,
            })
          )
        )
      );
  }

  public GetMatches(seasonId: string): Observable<Match[]> {
    return this.http
      .get(`${this.env.apiUrl}/api/season/${seasonId}/matches`)
      .pipe(
        map((data: any) =>
          data.matches.map(
            (m: any): Match => ({
              MatchID: m.matchId,
              MatchDay: m.matchSet.name,
              MatchDate: m.matchDateUtc.split('T')[0],
              MatchData: {
                HomeTeamID: m.home.teamId,
                AwayTeamID: m.away.teamId,
                CompetitionID: m.matchSet.competitionId,
                SeasonID: m.matchSet.seasonId,
                StadiumID: m.stadiumId,
                HomeTeam: m.home.officialName,
                AwayTeam: m.away.officialName,
              },
            })
          )
        )
      );
  }
}
