<main class="d-flex layout">
  <app-competition-selector></app-competition-selector>
  <ui-vertical-divider
    [width]="'1px'"
    [color]="'var(--text-5)'"
  ></ui-vertical-divider>
  <app-matchday-selector></app-matchday-selector>
  <ui-vertical-divider
    [width]="'1px'"
    [color]="'var(--text-5)'"
  ></ui-vertical-divider>
  <app-report-screen class="main-container"></app-report-screen>
</main>
