import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';
import {
  SelectMatchDay,
  SelectRound,
  SelectSession,
} from '../../states/app-state/app-state.actions';
import { AppStateModel } from '../../states/app-state/app-state.model';
import { Match, MatchDay } from '../../services/sdp.messages';

@Component({
  selector: 'app-matchday-selector',
  templateUrl: './matchday-selector.component.html',
  styleUrl: './matchday-selector.component.less',
})
export class MatchdaySelectorComponent implements OnInit {
  matchDays: TreeNode[] = [];
  matches: Match[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select((state: { app: AppStateModel }) => state.app.matchDays)
      .subscribe((matchDays) => this.buildTree(matchDays));
  }

  buildTree(data: MatchDay[]): void {
    if (!data) return;

    this.matchDays = this.mappers.mapMatchDays(data);
  }

  nzEvent(event: NzFormatEmitEvent): void {
    const node = event.node;

    if (!node) return;

    if (true && event.eventName === 'expand') {
      if (node.isExpanded) this.loadChildren(node);
      else node.clearChildren();
    } else if (event.eventName === 'click') {
      if (node.isExpanded) {
        node.clearChildren();
        node.isExpanded = false;
      } else this.loadChildren(node);
    }
  }

  loadChildren(node: NzTreeNode) {
    const nodeOrigin = node.origin as TreeNode;
    switch (nodeOrigin?.type) {
      case NodeType.Round:
        this.store.dispatch(new SelectRound(nodeOrigin?.roundId));
        break;
      case NodeType.MatchDay:
        this.store.dispatch(new SelectMatchDay(nodeOrigin?.matchDayId));
        break;
      case NodeType.Session:
        this.store.dispatch(new SelectSession(nodeOrigin?.sessionId));
        break;
      default:
        break;
    }
  }

  mappers = {
    mapMatches: (matches: Match[]): TreeNode[] => {
      const matchNodes = matches.map((m) => {
        const matchNode: TreeNode = {
          key: m.MatchID!,
          title: m.MatchData?.HomeTeamID + ' vs ' + m.MatchData?.AwayTeamID,
          type: NodeType.Match,
          matchId: m.MatchID,
          expanded: false,
        };

        return matchNode;
      });

      return matchNodes;
    },
    mapMatchDays: (matchDays: MatchDay[]): TreeNode[] => {
      let index = 0;

      const matchDaysNodes = matchDays.map((matchDay) => {
        const matchDayNode: TreeNode = {
          key: matchDay.MatchDay ?? (index++).toString(),
          title: matchDay.Name ?? '',
          type: NodeType.MatchDay,
          matchDayId: matchDay.MatchDay,
          isLeaf: true,
          // children: this.mappers.mapMatches(matchDay.Matches!),
        };

        return matchDayNode;
      });

      return matchDaysNodes;
    },
  };
}

interface TreeNode {
  key: string;
  title: string;
  expanded?: boolean;
  isLeaf?: boolean;
  type?: NodeType;
  roundId?: string;
  matchDayId?: string;
  sessionId?: string;
  matchId?: string;
  children?: TreeNode[];
}

enum NodeType {
  Round,
  MatchDay,
  Session,
  Match,
}
