import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-secondary',
  templateUrl: './navbar-secondary.component.html',
  styleUrl: './navbar-secondary.component.less'
})
export class NavbarSecondaryComponent {

}
