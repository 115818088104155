import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetReportForMatch, GetReportTemplates } from './reporting.messages';
import { EnvService } from '../../environments/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  getReportTemplates(
    request: GetReportTemplates.Request
  ): Observable<GetReportTemplates.Response> {
    return this.http.post<GetReportTemplates.Response>(
      `${this.env.apiUrl}/api/reporting/statuses`,
      request
    );
  }

  constructor(private http: HttpClient, private env: EnvService) {}

  getReport(reportId?: string | null): Observable<GetReportForMatch.Response> {
    return this.http.get<GetReportForMatch.Response>(
      `${this.env.apiUrl}/reports/${reportId}`
    );
  }

  public createMatchReportRequest(
    matchId: string | null | undefined,
    reportTemplateId: string | null | undefined,
    seasonId: string | null | undefined,
    competitionId: string | null | undefined,
  ): Observable<any> {
    return this.http.post<any>(`${this.env.apiUrl}/api/reporting/match`, {
      matchId,
      reportTemplateId,
      seasonId,
      competitionId,
    });
  }

  public createSessionReportRequest(
    sessionId: string | null | undefined,
    reportTemplateId: string | null | undefined
  ): Observable<any> {
    return this.http.post<any>(`${this.env.apiUrl}/api/reporting/session`, {
      sessionId: sessionId,
      reportTemplateId: reportTemplateId,
    });
  }
}
