import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-primary',
  templateUrl: './navbar-primary.component.html',
  styleUrl: './navbar-primary.component.less'
})
export class NavbarPrimaryComponent {

}
