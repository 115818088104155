<nav class="navbar navbar-expand-sm navbar-dark navbar-primary">
  <div class="container-fluid">
    <a
      class="navbar-brand"
      href="#"
    >Home</a>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link active"
          href="#"
        >Admin</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
        >Logout</a>
      </li>
    </ul>
  </div>
</nav>