<div class="flex-shrink-0 sidebar-competition-selector">
  <nz-collapse>
    @for (competition of competitions; track competition) {
    <nz-collapse-panel
      [nzShowArrow]="false"
      [nzHeader]="competition.Name"
      [nzActive]="
        competition.CompetitionID === selectedCompetition?.CompetitionID
      "
      (nzActiveChange)="onCollapseChange($event, competition.CompetitionID)"
    >
      <nz-list>
        @for (season of seasons; track season) {
        <nz-list-item (click)="onSeasonSelect(season.SeasonID)">
          <span nz-typography>{{ season.Name }}</span>
        </nz-list-item>
        }
      </nz-list>
      <nz-spin *ngIf="seasonsLoading" nzTip="Loading...">
        <nz-alert nzType="info" nzMessage=" " nzDescription=" "></nz-alert>
      </nz-spin>
    </nz-collapse-panel>
    }
  </nz-collapse>
</div>
