<div style="padding: var(--padding-1)">
  <nz-card
    style="width: 100%"
    [nzBordered]="false"
    [nzTitle]="
      match?.MatchData?.HomeTeam +
      ' vs ' +
      match?.MatchData?.AwayTeam +
      ' (' +
      match?.MatchID +
      ')'
    "
  >
    <div class="d-flex flex-row gap-4">
      @for (matchReportTemplate of matchReportBundle?.matchReportTemplates;
      track matchReportTemplate) {
      <div
        class="d-flex flex-column align-items-center gap-2 p-2"
        style="border-radius: 5px; border: solid 1px var(--text-5)"
      >
        <span
          nz-icon
          nzType="file-pdf"
          nzTheme="outline"
          style="font-size: xx-large; cursor: pointer"
          (click)="onReportClick(match?.MatchID, matchReportTemplate.reportTemplateId)"
        ></span>

        <div
          class="d-flex flex-row justify-content-between"
          style="width: 40px"
        >
          <span
            nz-icon
            nzType="file"
            nzTheme="fill"
            [style.color]="
              statusColors[matchReportTemplate?.report?.status ?? 'undefined']
            "
          ></span>
          <span
            nz-icon
            nzType="link"
            nzTheme="outline"
            style="cursor: pointer"
            (click)="onOpenLinkInNewTab(matchReportTemplate?.report?.id)"
          ></span>
        </div>
        {{ matchReportTemplate.name }}
      </div>
      }
    </div>
  </nz-card>
</div>
