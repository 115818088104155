import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { AppStateModel } from "../states/app-state/app-state.model";

@Injectable({ providedIn: "root" })
export class StateService {
    constructor(public store: Store) { }
    get states(): Observable<AppStateModel> { return this.store.select((state: AppStateModel) => state); }
    get snapshot(): AppStateModel { return this.store.selectSnapshot((state: AppStateModel) => state); }
}