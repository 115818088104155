import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-vertical-divider',
  templateUrl: './ui-vertical-divider.component.html',
  styleUrl: './ui-vertical-divider.component.less'
})
export class UiVerticalDividerComponent {
  @Input() width?: string;
  @Input() color?: string;
}
